import React from 'react'

import {Row, Col} from 'react-bootstrap'

export default function Footer() {
    return (
        <div className="container-fluid purple-bg">

                <div className="container">
    
                  <div className="consistent-gap">
    
                    <div className="content careers-content">

                      <Row className="pb-4">

                          <Col md={6} xs={12} className="footer-links text-left">
                             {/*eslint-disable-next-line*/ }
                              <a href="#"><svg className="down-arrow" height="20" viewBox="0 0 32 32"><g fillRule="evenodd"><path transform="rotate(-45 26.616 9.647)" d="M5.364 -0.236H7.364V15.764H5.364z"></path><path transform="scale(-1 1) rotate(-45 -1.419 53.188)" d="M5.364 9.764H7.364V25.764H5.364z"></path><path d="M2 14H26V16H2z" transform="rotate(-90 15 14)"></path></g></svg> Top</a>
                           </Col>
      
                          <Col md={6} xs={12} className="footer-links text-right text-white">
                            <p>Copyright &copy; {new Date().getFullYear()}. The Able Guys</p>
                          </Col>

                      </Row>

                    </div>
    
                  </div>
                  
    
                </div>
    
        </div>
    )
}
