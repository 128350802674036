import React from 'react'
import NavbarTop from './Navbar'
import Footer from './Footer'

import '../styles/global.css'

export default function Layout({page, children}) {
    return (    
               <>
               <div className={`container ${page}-container`}>
                    <div className={`content ${page==="thanks" ? "home" : page}-content`}>
                        <NavbarTop page={page}/>
                        {(page !== "careers") && children}                        
                    </div>
                </div>
                {(page === "careers") && children }
                {(page === "careers") && <Footer/> }
                </>
                
    )
}
